import React, { useCallback } from 'react';

import { useSettings } from '@wix/tpa-settings/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { withProviders } from '../../../contexts/main/withProviders';
import { withGeneralData } from '../../../contexts/GeneralDataProvider/withGeneralData';
import { withSettingsEvents } from '../../../contexts/SettingsEvents/SettingsEvents';
import { withLocation } from '../../../contexts/Location/withLocation';
import { withChallengesList } from '../../../contexts/storage-contexts/ChallengesList/withChallengesList';
import { withPaidPlans } from '../../../contexts/storage-contexts/PaidPlans/withPaidPlans';
import { applyProviders } from '../../../services/applyProviders';

import { ChallengesList } from './ChallengesList';

import { IChallengeListStateProps } from './interfaces';
import { orderChallenges } from './utils';
import { classes, st } from './Widget.st.css';
import challengeSettings, { ALL_PROGRAM_CATEGORY } from '../settingsParams';

import {
  getCategoriesSetup,
  filterChallengesByCategory,
} from '../../../selectors/categories';
import { filterChallenges } from './filterChallenges';
import { FCWithChildren } from '@wix/challenges-web-library';
import { useCSSPBEnabled } from '../../../hooks/useCSSPBStyle';
import CategoriesMenu from './CategoriesMenu/CategoriesMenu';
import EmptyList from './EmptyList/EmptyList';
import { useCategories } from '../../../contexts/storage-contexts/Categories';

export const ChallengeList: FCWithChildren<IChallengeListStateProps> = ({
  challengesListData,
}) => {
  const settings = useSettings();
  const { categoriesData, categoryToPreview } = useCategories();
  const { isMobile } = useEnvironment();
  const [currentCategory, setCurrentCategory] =
    React.useState<string>(ALL_PROGRAM_CATEGORY);

  const {
    isCategoriesEnabled,
    chosenCategoryFromSettings,
    isAllProgramCategory,
    isExistingInDBCategory,
    isShowCategoriesMenu,
  } = getCategoriesSetup(settings, categoriesData?.categories);
  const isUseChosenCategoryFromSettings =
    chosenCategoryFromSettings &&
    !isAllProgramCategory &&
    isExistingInDBCategory;

  const onCategoryChange = useCallback((categoryId: string) => {
    categoryId && setCurrentCategory(categoryId);
  }, []);

  const chVisible = settings.get(challengeSettings.challengesVisible);

  const filteredChallenges = filterChallenges(
    challengesListData?.memberChallenges || [],
    chVisible,
  );
  const usedCategory =
    categoryToPreview ||
    (isUseChosenCategoryFromSettings
      ? chosenCategoryFromSettings
      : currentCategory);
  const filteredChallengesByCategory = filterChallengesByCategory(
    filteredChallenges || [],
    usedCategory,
  );
  const orderedChallenges = orderChallenges(
    isCategoriesEnabled ? filteredChallengesByCategory : filteredChallenges,
    settings,
    usedCategory,
    categoriesData?.categories || [],
  );

  const TitleTag = settings.get(challengeSettings.listTitleTag) || 'h1';
  const cssPBEnabled = useCSSPBEnabled();

  const showTitle = cssPBEnabled || settings.get(challengeSettings.dpListTitle);
  // moved logic from controller
  const categories = categoriesData?.categories || [];

  return (
    <div
      data-hook="challenge-list-wrapper"
      className={st(classes.root, {
        mobile: isMobile,
        titleAlignment: cssPBEnabled
          ? undefined
          : settings.get(challengeSettings.listTitleAlignment),
      })}
    >
      {showTitle ? (
        <TitleTag
          className={st(
            classes.listTitle,
            cssPBEnabled ? classes.opgDpListTitle : undefined,
          )}
          data-hook="card-title"
        >
          {settings.get(challengeSettings.listTitleText) as string}
        </TitleTag>
      ) : null}

      {isCategoriesEnabled && isShowCategoriesMenu && categories.length >= 1 ? (
        <CategoriesMenu categories={categories} onChange={onCategoryChange} />
      ) : null}

      {orderedChallenges?.length ? (
        <ChallengesList challenges={orderedChallenges} />
      ) : (
        <EmptyList />
      )}
    </div>
  );
};

ChallengeList.displayName = 'ChallengesList';

export default applyProviders(ChallengeList, [
  withProviders,
  withLocation,
  withSettingsEvents,
  withGeneralData,
  withChallengesList,
  withPaidPlans,
]);
