import { ChallengeServerlessData } from '../../../types/challenge-serverless';
import type { SectionWithSteps, StepWithDelay } from '../../../types/v3Types';

export interface IChallengeContext {
  isDescriptionShown: boolean;
  challengeData: ChallengeServerlessData;
  challengeSections?: SectionWithSteps[];
  challengeSteps?: StepWithDelay[];
  requestChallengeSections?: Function;
  requestChallengeSteps?: Function;
  instructorUrls?: { [id: string]: string };
}

export const defaultChallengeData = {
  challenge: null,
  badgesData: null,
  groupData: null,
  instructorsData: null,
};

export const challengeDefaultValue = {
  isDescriptionShown: false,
  challengeData: defaultChallengeData,
  challengeSections: null,
  challengeSteps: null,
  requestChallengeSections: null,
  requestChallengeSteps: null,
};
