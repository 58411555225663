import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  getChallengeSlugFromLocation,
  getUrlParams,
  isHomePage,
} from '../../Location/locationProviderPropsMap';
import requestChallenge from './api/requestChallenge';
import { isMA } from '../../../selectors/isMA';
import { ChallengeServerlessData } from '../../../types/challenge-serverless';
import { shouldReportErrorMonitor } from './helpers/shouldReportErrorMonitor';
import { handleError } from '../../ErrorHandler/errorHandlerPropsMap';
import { getChallengesListWithMocks } from '../ChallengesList';
import { defaultChallengeData, IChallengeContext } from './interfaces';
import { handleChallengeAfterLogin } from './helpers/handleChallengeAfterLogin';
import { requestChallengeSections, requestChallengeSteps } from './api';
import {
  ChallengeSection,
  ChallengeStep,
} from '@wix/ambassador-challenges-v1-challenge/types';
import { Referrer } from '../../storage/referrer';
import { getProfilePageUrls } from './helpers/getProfilePageUrls';
import { isV3enabled } from '../../../experiments/isV3enabled';
import { getSectionsWithSteps } from '../../../selectors/getSectionsWithSteps';

// todo: add warmup

let CHALLENGE_DATA: ChallengeServerlessData = defaultChallengeData;

const getSlugFromList = async (flowAPI: ControllerFlowAPI): Promise<string> => {
  let challengeIdOrSlug = '';

  try {
    const challengesListData = await getChallengesListWithMocks(flowAPI);
    const firstChallenge = challengesListData?.memberChallenges[0]?.challenge;

    if (firstChallenge) {
      challengeIdOrSlug =
        firstChallenge?.settings?.seo?.slug || firstChallenge?.id;
    }
  } catch (err) {
    handleError({
      error: err,
      context: 'getChallengeIdFromList',
      preventErrorMonitorReport: !shouldReportErrorMonitor(err),
    });
  }

  return challengeIdOrSlug;
};

export const getChallengeData = async (
  flowAPI: ControllerFlowAPI,
  referrer?: Referrer,
): Promise<ChallengeServerlessData> => {
  const { isPreview, isEditor } = flowAPI.environment;
  const isPreviewOrEditor = isPreview || isEditor;
  let challengeIdOrSlug = getChallengeSlugFromLocation(flowAPI);

  if (CHALLENGE_DATA?.challenge !== null) {
    return CHALLENGE_DATA;
  }
  const shouldGetSlugFromList =
    !challengeIdOrSlug && (isPreviewOrEditor || isHomePage(flowAPI));
  if (shouldGetSlugFromList) {
    challengeIdOrSlug = await getSlugFromList(flowAPI);
  }

  if (challengeIdOrSlug && !isMA(flowAPI)) {
    try {
      CHALLENGE_DATA = await requestChallenge(
        challengeIdOrSlug,
        flowAPI,
        referrer,
      );

      flowAPI.bi?.updateDefaults({
        challengeId: CHALLENGE_DATA?.challenge?.id,
      });
    } catch (err) {
      if (!isPreviewOrEditor) {
        // needed to redecue count of failed calls when challenge is not returned.
        // this func called in many places
        CHALLENGE_DATA = { challenge: {} };

        if (err.response.status === 404) {
          // send status for seo for robots
          flowAPI.controllerConfig.wixCodeApi.seo.setSeoStatusCode(404);
        }

        if (err.httpStatus !== 404) {
          handleError({
            error: err,
            context: 'getOwnerChallenge',
            preventErrorMonitorReport: !shouldReportErrorMonitor(err),
          });
        }
      } else {
        console.error(err);
      }
    }
  }

  return CHALLENGE_DATA;
};

export const getChallengeInitialData = async (
  flowAPI: ControllerFlowAPI,
  referrer?: Referrer,
): Promise<IChallengeContext> => {
  const urlParams = getUrlParams(flowAPI);
  const challengeData = await getChallengeData(flowAPI, referrer);
  const instructorUrls = await getProfilePageUrls(
    flowAPI,
    challengeData?.instructorsData?.instructors,
  );

  void handleChallengeAfterLogin();

  return {
    isDescriptionShown: urlParams.navigationType === 'description',
    challengeData,
    instructorUrls,
    requestChallengeSections: async (idOrSlug: string): Promise<void> => {
      let sections: ChallengeSection[] = [];

      try {
        if (isV3enabled(flowAPI)) {
          const [sectionsV3, stepsV3] = await Promise.all([
            requestChallengeSections(idOrSlug, flowAPI),
            requestChallengeSteps(idOrSlug, flowAPI),
          ]);
          const { sections, steps } = getSectionsWithSteps(sectionsV3, stepsV3);
          flowAPI.controllerConfig.setProps({
            challengeSections: sections,
            challengeSteps: steps,
          });
          return;
        }
        sections = await requestChallengeSections(idOrSlug, flowAPI);
      } catch (err) {
        handleError({
          error: err,
          context: 'requestChallengeSections',
          preventErrorMonitorReport: !shouldReportErrorMonitor(err),
        });
        console.error(err);
      }

      flowAPI.controllerConfig.setProps({
        challengeSections: sections,
      });
    },
    requestChallengeSteps: async (idOrSlug: string): Promise<void> => {
      let steps: ChallengeStep[] = [];

      try {
        steps = await requestChallengeSteps(idOrSlug, flowAPI);
      } catch (err) {
        handleError({
          error: err,
          context: 'requestChallengeSteps',
          preventErrorMonitorReport: !shouldReportErrorMonitor(err),
        });
        console.error(err);
      }

      flowAPI.controllerConfig.setProps({
        challengeSteps: steps,
      });
    },
  };
};

export const resetChallengeCache = () => {
  CHALLENGE_DATA = defaultChallengeData;
};
