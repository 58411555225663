import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { ISettingsContextValue } from '@wix/tpa-settings';
import {
  ListChallengesResponse,
  MemberRole,
  SortingCriterion,
} from '@wix/ambassador-challenges-v1-challenge/types';
import { requestAllChallenges } from './api/requestChallenges';
import { IChallengesListContext } from './interfaces';
import { getUserFromConfig } from '../../User/helpers/userContextHelpers';
// import { WarmupData } from '../../../services/WarmupData';
import { getCategoriesSetup } from '../../../selectors/categories';
import { isMA } from '../../../selectors/isMA';
import { getMockedChallenges } from '../../main/getMockedChallenges';
import {
  generateLinksToProgramForList,
  isUserGoesToParticipantPage,
  navigateToProgramPage,
} from '../../Location/helpers/generateLinksToProgramForList';
import handleChallengesAfterLogin from './helpers/handleChallengesAfterLogin';
import { handleError } from '../../ErrorHandler/errorHandlerPropsMap';

// todo: fix warmup
// const WARMUP_DATA_KEY = 'programsList';
const CHALLENGES_LIST_DATA: { [index: string]: ListChallengesResponse } = {};

export const getChallengesListWithMocks = async function (
  flowAPI: ControllerFlowAPI,
): Promise<ListChallengesResponse> {
  const { isViewer } = flowAPI.environment;
  const userData = await getUserFromConfig(flowAPI.controllerConfig);
  const memberId = userData.id;
  // const categories =
  //   (await getCategoriesData(flowAPI))?.categoriesData?.categories || [];

  const {
    isCategoriesEnabled,
    chosenCategoryFromSettings,
    isAllProgramCategory,
    // isExistingInDBCategory,
  } = getCategoriesSetup(flowAPI.settings as ISettingsContextValue);
  const chosenCategory =
    // isCategoriesEnabled && !isAllProgramCategory && isExistingInDBCategory
    isCategoriesEnabled && !isAllProgramCategory
      ? chosenCategoryFromSettings
      : null;
  const cacheKey =
    chosenCategory && isViewer
      ? `${chosenCategory}_${memberId}`
      : `__all__${memberId}`;

  if (CHALLENGES_LIST_DATA[cacheKey]) {
    return CHALLENGES_LIST_DATA[cacheKey];
  }

  try {
    CHALLENGES_LIST_DATA[cacheKey] = await requestAllChallenges({
      flowAPI,
      memberId: userData.loggedIn ? memberId : undefined,
      options: {
        memberRoles: isMA(flowAPI) && isViewer ? [MemberRole.PARTICIPANT] : [],
        sortingCriterion: isMA(flowAPI)
          ? SortingCriterion.ACTIVE_FIRST
          : SortingCriterion.DEFAULT,
        categoryIds: chosenCategory && isViewer ? [chosenCategory] : null,
      },
    });
  } catch (error) {
    console.error(error);
    handleError({
      error,
      context: 'getChallengesList',
    });
  }

  if (
    !isMA(flowAPI) &&
    !isViewer &&
    !CHALLENGES_LIST_DATA[cacheKey]?.totalCount
  ) {
    CHALLENGES_LIST_DATA[cacheKey] = getMockedChallenges(flowAPI) as any;
  }

  return CHALLENGES_LIST_DATA[cacheKey];
};

export const getChallengesListInitialData = async function (
  flowAPI: ControllerFlowAPI,
): Promise<Partial<IChallengesListContext>> {
  // const warmupData = new WarmupData(flowAPI);
  // const ssrData = warmupData.get(WARMUP_DATA_KEY);

  void handleChallengesAfterLogin();

  const challenges = await getChallengesListWithMocks(flowAPI);

  // warmupData.set(WARMUP_DATA_KEY, challenges);

  return {
    challengesListData: {
      ...challenges,
    },
    linksToProgram: await generateLinksToProgramForList(
      flowAPI,
      challenges?.memberChallenges,
    ),
    goToProgramPage: async (programId: string) => {
      const memberProgram = challenges.memberChallenges.find(
        (i) => i.challenge.id === programId,
      );

      if (memberProgram) {
        await navigateToProgramPage(flowAPI, {
          challengeId: memberProgram.challenge.id,
          isParticipant: isUserGoesToParticipantPage(memberProgram?.summary),
        });
      }
    },
  };
};
