import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { handleError } from '../../ErrorHandler/errorHandlerPropsMap';
import { Challenges } from '../../../editor/types/Experiments';
import {
  CHALLENGE_PAGE_ID,
  PARTICIPANT_PAGE_ID,
} from '../../../editor/app-config';
import {
  Challenge,
  ChallengeSummary,
  MemberChallenge,
} from '@wix/ambassador-challenges-v1-challenge/types';
import { State } from '@wix/ambassador-challenges-v1-participant/types';
import { getParticipantStateFromSummary } from '../../../components/ChallengesList/Badge/helpers/GetParticipantStateFromSummary';
import { locationProviderPropsMap } from '../locationProviderPropsMap';
import {
  getChallengePageSectionUrl,
  getParticipantPageSectionUrl,
  isParticipantPageInstalled,
} from '../../../services/wixCodeApi';

export const getProgramSlug = (program: Challenge) => {
  return program?.settings?.seo?.slug;
};
export const isUserGoesToParticipantPage = (
  summary: ChallengeSummary,
): boolean => {
  return [
    State.COMPLETED,
    State.FAILED,
    State.JOINED,
    State.RUNNING,
    State.SUSPENDED,
  ].includes(getParticipantStateFromSummary(summary));
};

export const isParticipantPageAvailable = async (
  flowAPI: ControllerFlowAPI,
): Promise<boolean> => {
  if (flowAPI.experiments.enabled(Challenges.redirectToParticipantPage)) {
    const isParticipantPageInstalled_ = await isParticipantPageInstalled(
      flowAPI,
    );

    return !!isParticipantPageInstalled_;
  }

  return false;
};

export async function getProgramPageUrl(
  flowAPI: ControllerFlowAPI,
  isParticipant: boolean,
  isParticipantPageAvailable: boolean,
): Promise<any> {
  try {
    if (isParticipant && isParticipantPageAvailable) {
      return await getParticipantPageSectionUrl(flowAPI);
    } else {
      return await getChallengePageSectionUrl(flowAPI);
    }
  } catch (error) {
    handleError({
      error,
      context: 'getSectionUrl',
    });
  }

  return null;
}
export const generateLinksToProgramForList = async (
  flowAPI: ControllerFlowAPI,
  membersPrograms: MemberChallenge[] = [],
) => {
  const linksToProgram: {
    [id: string]: string;
  } = {};
  const isParticipantPageAvailable_ = await isParticipantPageAvailable(flowAPI);

  await Promise.all(
    membersPrograms.map(async (memberProgram) => {
      const urlInfo = await getProgramPageUrl(
        flowAPI,
        isUserGoesToParticipantPage(memberProgram.summary),
        isParticipantPageAvailable_,
      );
      linksToProgram[memberProgram.challenge.id] = `${
        urlInfo.url
      }/${getProgramSlug(memberProgram.challenge)}`;
    }),
  );

  return linksToProgram;
};

export const navigateToProgramPage = async (
  flowAPI: ControllerFlowAPI,
  options: { challengeId: string; isParticipant: boolean; queryParams?: any },
): Promise<void> => {
  return locationProviderPropsMap(flowAPI).goToPage({
    challengeId: options.challengeId,
    pageId:
      (await isParticipantPageAvailable(flowAPI)) && options.isParticipant
        ? PARTICIPANT_PAGE_ID
        : CHALLENGE_PAGE_ID,
    queryParams: options.queryParams || null,
  });
};
